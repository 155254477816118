import { Outlet, Link } from "react-router-dom";
export default function One() {
  return (
    <main>
      <p><b>Email</b> - benjaminrbarnes@gmail.com<br/>
      <b>Instagram</b> - <a href="https://www.instagram.com/benjaminbarnes/">@benjaminbarnes</a><br/>
      <b>LinkedIn</b> - <a href="https://www.linkedin.com/in/benjaminrbarnes/">Benjamin Barnes</a>
      </p>
    </main>
  );
}
