import { Outlet, Link } from "react-router-dom";
export default function Two() {
  return (
    <main>
      <p>
      <a href="https://benjaminrbarnes.notion.site/Website-Thoughts-c7936989cd364dd9979a5a8d104e86e8?pvs=4">Notepad</a>
      </p>
    </main>
  );
}
