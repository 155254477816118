import { Outlet, Link } from "react-router-dom";
export default function One() {
  return (
    <main>
        <div class="row">
          <div class="col">
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img1.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img2.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img3.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img4.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img5.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img6.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img7.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img8.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img9.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img10.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img11.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img12.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img13.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img14.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img15.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img16.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img17.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img18.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img19.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img20.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img21.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img22.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img23.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img24.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img25.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img26.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img27.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img28.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img29.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img30.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img31.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img32.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img33.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img34.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img35.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img36.jpg"/>
            <img class="img-fluid" style={{"max-height": "1000px", "display": "block", "margin": "auto"}} src="https://d3dsxdu5t5tqsc.cloudfront.net/photos/5-sept2021-3/md/img37.jpg"/>
          </div>
        </div>


    </main>
  );
}
